import Axios from "axios";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import environmentVariable from "../envViables";
import {
  Form,
  Input,
  Typography,
  Button,
  Switch,
  Row,
  Col,
  Divider,
  DatePicker,
  message,
  Spin,
  Radio,
  List,
  Avatar,
} from "antd";

const { Title } = Typography;
export default function SearchInvoice() {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const getInvoice = async (values) => {
    const { email, invoiceId } = values;

    const response = await Axios.post(
      environmentVariable.apiURL + `/${email}/${invoiceId}`
    );
    console.log(response);
    if (!response.data) {
      message.error(
        "No record found. Please use correct email and invoice ID and try again."
      );
    }
    if (response.data) {
      history(`/${email}/${invoiceId}`);
    }
  };
  const onFinish = (values) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      getInvoice(values);
    }, 1000);

    // form.resetFields();
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  return (
    <div>
      <Divider />
      <Title level={3}> Search Invoice and Add Guests </Title>

      <Spin spinning={loading}>
        <Form
          layout={"vertical"}
          form={form}
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            label="Your Email"
            name={"email"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Invoice ID"
            name={"invoiceId"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
}
