import {
  Form,
  Input,
  InputNumber,
  Button,
  Switch,
  Row,
  Col,
  Divider,
  DatePicker,
  message,
  Spin,
  Radio,
  Steps,
  Typography,
} from "antd";
import {
  UserOutlined,
  SolutionOutlined,
  LoadingOutlined,
  SmileOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";

import SelectTicketType from "./formComponents/selectTicketType";
import Payments from "./formComponents/payments";
import Billing from "./formComponents/billing";
import Confirmation from "./formComponents/confirmation";
import React, { useEffect, useState } from "react";

import Axios from "axios";
import environmentVariable from "../envViables";
const { Title } = Typography;
const layout = {
  wrapperCol: {
    span: 24,
  },
};
const { Step } = Steps;
const gutterLayout = { xs: 8, sm: 16, md: 24, lg: 32 };

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
const golfTicket = "38";
const golfReception = "39";
const golfContribution = "37";
const Checkoutform = () => {
  const [ticketAmount, setTicketAmount] = useState({
    golf: 0,
    cocktail: 0,
    contribution: 0,
  });
  const [ifWithGuests, setIfWithGuests] = useState(false);
  const [isMakingContribution, changeIntoContribution] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [ticket, setTicket] = useState({
    golf: 500,
    cocktail: 200,
    contribution: 1,
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [confirmationMessage, setConfirmationMessage] = useState();
  const [formValues, setFormValues] = useState({});
  const [salesItems, setSalesItems] = useState([]);
  const [gettingValues, setGettingValues] = useState(true);
  useEffect(async () => {
    await getItem();
  }, []);

  useEffect(() => {
    if (ticketAmount.cocktail > 0 && ticketAmount.golf > 0) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [ticketAmount]);

  const getItem = async () => {
    console.log("getting items");
    var baseUrl = environmentVariable.apiURL + "/ticket";

    const response = await Axios.post(baseUrl, {
      galaTicketIds: [golfTicket, golfReception, golfContribution],
    });

    if (response.status == 200) {
      var newData = response.data;
      setSalesItems(newData);
      var newTicket = {};

      for (const salesItem of newData) {
        if (salesItem.Id == golfTicket) {
          newTicket.golf = salesItem.UnitPrice;
        }
        if (salesItem.Id == golfReception) {
          newTicket.cocktail = salesItem.UnitPrice;
        }
        if (salesItem.Id == golfContribution) {
          newTicket.contribution = salesItem.UnitPrice;
        }
      }

      setTicket({ ...newTicket });
    }
  };
  const onFinish = async (values) => {
    if (values.sellingItems) {
      formValues.sellingItems = values.sellingItems;
    }
    setFormValues({ ...values, ...formValues });

    var message = "Success! You will receive a confirmation shortly.";

    if (currentStep == 0) {
      setCurrentStep(currentStep + 1);
    }
    if (currentStep == 1) {
      await checkAndCreateCustomer(values);
    }
    if (currentStep == 2) {
      if (values.paymentMethod && values.paymentMethod == "check") {
        message = "Success! You will receive a confirmation shortly.";
      }
      setConfirmationMessage(message);
      console.log(formValues);
      var newFormValues = { ...formValues, ...values };
      for (const salesItem of salesItems) {
        if (salesItem.Id == golfTicket) {
          salesItem.quantity = ticketAmount.golf;
        }
        if (salesItem.Id == golfReception) {
          salesItem.quantity = ticketAmount.cocktail;
        }
        if (salesItem.Id == golfContribution) {
          salesItem.quantity = ticketAmount.contribution;
        }
      }
      newFormValues.salesItems = salesItems;

      console.log(newFormValues);
      submitInfo(newFormValues);
    }
  };

  const checkAndCreateCustomer = async (values) => {
    var baseUrl = environmentVariable.apiURL + "/create-customer";

    setLoadingCustomer(true);
    try {
      const response = await Axios.post(baseUrl, values);
      console.log(response);
      setLoadingCustomer(false);
      if (response.status == 200) {
        setCurrentStep(currentStep + 1);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        message.error({
          content: "Sorry, there was an error. " + error.response.data.message,
          className: "messageBox",
        });
      }

      setLoadingCustomer(false);
    }
  };
  const submitInfo = async (values) => {
    var baseUrl = environmentVariable.apiURL + "/charge";

    setLoading(true);
    try {
      const response = await Axios.post(baseUrl, values);
      console.log(response);
      if (response.status == 200) {
        setCurrentStep(currentStep + 1);
        form.resetFields();
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        message.error({
          content: "Sorry, there was an error. " + error.response.data.message,
          className: "messageBox",
        });
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const onSelectTicket = async (amount, type) => {
    var newTicketAmount = ticketAmount;
    newTicketAmount[type] = amount;
    setTicketAmount({ ...newTicketAmount });
  };

  const goPrevious = () => {
    setCurrentStep(currentStep - 1);
  };
  const showFormContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <SelectTicketType
            {...{
              isMakingContribution: isMakingContribution,
              showAlert: showAlert,
            }}
            confirmTicket={() => {
              setShowAlert(false);
            }}
            cancleTicket={() => {
              form.setFieldsValue({ sellingItems: { cocktail: 0 } });
              onSelectTicket(0, "cocktail");
            }}
            onChangeType={(contribution) => {
              changeIntoContribution(contribution);
            }}
            onChange={(amount, type) => {
              console.log(ticketAmount);
              onSelectTicket(amount, type);
            }}
          />
        );
      case 1:
        return (
          <Spin spinning={loadingCustomer}>
            <Billing {...{ isMakingContribution: isMakingContribution }} />
          </Spin>
        );
      case 2:
        return <Payments />;
      case 3:
        return <Confirmation confirmationMessage={confirmationMessage} />;
      default:
    }
  };
  const ShowTotal = () => {
    const totalPrice = () => {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      var total = 0;
      var keys = Object.keys(ticketAmount);
      keys.forEach((key) => {
        total += ticket[key] * ticketAmount[key];
      });

      return formatter.format(total);
    };
    return (
      <div className="totalAmount">
        <h4>Total: {totalPrice()}</h4>
        <Divider />
        <p>Golf Tickets: {ticketAmount["golf"]}</p>{" "}
        <p>Price: $ {ticket["golf"]} / Ticket</p>
        <p>Cocktail Tickets: {ticketAmount["cocktail"]}</p>{" "}
        <p>Price: $ {ticket["cocktail"]} / Ticket</p>
        <p>Contribution: $ {ticketAmount["contribution"]} </p>
      </div>
    );
  };

  return (
    <Row gutter={gutterLayout}>
      <Title level={2}>Golf Outing</Title>
      <Steps
        current={currentStep}
        // onChange={(value) => setCurrentStep(value)}
      >
        <Step title="Ticket" icon={<UserOutlined />} />
        <Step title="Billing" icon={<SolutionOutlined />} />
        <Step
          title="Payments"
          icon={loading ? <LoadingOutlined /> : <CreditCardOutlined />}
        />
        <Step title="Confirmation" icon={<SmileOutlined />} />
      </Steps>
      <Col span={currentStep == 3 ? 24 : 18} style={{ marginTop: "2rem" }}>
        <Form
          form={form}
          {...layout}
          name="nest-messages"
          layout="vertical"
          onFinish={onFinish}
          validateMessages={validateMessages}
          initialValues={{
            guests: [{ first: "", last: "" }],
            sellingItems: { cocktail: 0, golf: 0 },
            paymentMethod: "card",
          }}
        >
          {showFormContent()}

          {currentStep == 3 ? (
            <></>
          ) : (
            !showAlert && (
              <Form.Item>
                <Row gutter={gutterLayout}>
                  {" "}
                  {currentStep == 0 ? (
                    <></>
                  ) : (
                    <Col span={12}>
                      <Button
                        type="primary"
                        disabled={loading ? true : false}
                        onClick={goPrevious}
                        block
                      >
                        Previous
                      </Button>
                    </Col>
                  )}
                  <Col span={12}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={loading ? true : false}
                      block
                    >
                      {currentStep == 2 ? "Confirm" : "Next"}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            )
          )}
        </Form>
      </Col>
      {currentStep == 3 ? (
        <></>
      ) : (
        <Col span={6} style={{ marginTop: "2rem" }}>
          <ShowTotal />
        </Col>
      )}
    </Row>
  );
};

export default Checkoutform;
