import React from "react";
import { Form, Input, Button, Space, Select } from "antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const { Option } = Select;
export default function addGuests() {
  return (
    <Form.List name="guests">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space
              key={key}
              style={{ display: "flex", marginBottom: 8 }}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, "first"]}
                rules={[{ required: true, message: "Missing first name" }]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, "last"]}
                rules={[{ required: true, message: "Missing last name" }]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, "type"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{ width: 250 }}
              >
                <Select placeholder="Ticket type">
                  <Option value="golf">Golf ticket</Option>
                  <Option value="cocktail">Cocktail reception ticket</Option>
                </Select>
              </Form.Item>

              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Add Guest Name
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}
