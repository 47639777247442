import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Home from "./page/home";
import Checkoutform from "./page/form";
import Addguests from "./page/addGuests";
import SearchInvoice from "./page/searchInvoice";
import Nav from "./layout/nav";

function App() {
  return (
    <Nav>
      <div className="app">
        <Router>
          <Routes>
            <Route path="*" element={<Home />} />
            <Route exact path="/" element={<Checkoutform />}></Route>
            <Route exact path="/update" element={<SearchInvoice />}></Route>
            <Route
              exact
              path="/:email/:invoiceid"
              element={<Addguests />}
            ></Route>
          </Routes>
        </Router>
      </div>
    </Nav>
  );
}

export default App;
