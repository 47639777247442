import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import environmentVariable from "../envViables";
import {
  Form,
  Input,
  Typography,
  Button,
  Switch,
  Row,
  Col,
  Divider,
  DatePicker,
  message,
  Spin,
  Radio,
  List,
  Avatar,
  Select,
} from "antd";
const { Title } = Typography;
const { Option } = Select;
export default function AddGuests() {
  const params = useParams();
  const [user, setUser] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  useEffect(async () => {
    await getUser(params);
  }, []);
  const getParams = () => {
    console.log(params);
  };

  const getUser = async (urlParams) => {
    const { email, invoiceid } = urlParams;

    const response = await Axios.post(
      environmentVariable.apiURL + `/${email}/${invoiceid}`
    );
    console.log(response.data);
    if (response.data) {
      setUser(response.data);
      setList(response.data.guests);
    }
  };
  const onFinish = (values) => {
    setLoading(true);
    setTimeout(() => {
      setList([...list, values]);
      user.guests = [...list, values];

      postData(user);
      setLoading(false);
    }, 1000);

    form.resetFields();
  };
  const postData = async (values) => {
    const reponse = await Axios.patch(
      environmentVariable.apiURL + `/adduser`,
      values
    );
  };
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  return (
    <div>
      <Title level={3}> Guests</Title>
      <List
        bordered="true"
        itemLayout="vertical"
        dataSource={list}
        renderItem={(item) => (
          <List.Item>
            {item.first + " " + item.last + "--" + item.type}
          </List.Item>
        )}
      />
      <Divider />
      <Title level={3}> Add Guests</Title>
      {list.length >= 10 ? (
        <></>
      ) : (
        <Spin spinning={loading}>
          <Form
            layout={"vertical"}
            form={form}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Form.Item
              label="First Name"
              name={"first"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name={"last"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Type"
              name={"type"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select>
                <Option value="golf">Golf ticket</Option>
                <Option value="cocktail">Cocktail reception ticket</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      )}
    </div>
  );
}
