import React from "react";
import { Typography, Alert } from "antd";
const { Text, Paragraph } = Typography;
export default function Nav({ children }) {
  const alertMessage = (
    <Paragraph>
      <Paragraph>
        Please make payment payable to HANYC FOUNDATION, INC.
      </Paragraph>
      <Paragraph>
        For your records, your contribution is tax-deductible to the fullest
        extent permitted by law. HANYC Foundation, Inc. is a 501(c)(3)
        non-profit organization. EIN #83-3283722
      </Paragraph>
      <Paragraph>
        Please contact accounting@hanycfoundation.org with any questions you may
        have in regard to the billing.
      </Paragraph>{" "}
    </Paragraph>
  );
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <a href="/">
            <img
              src={require("./foundationlogo.png")}
              style={{ maxWidth: "200px", margin: "1rem" }}
            />
          </a>
        </div>
        <div style={{ marginTop: "5px" }}>
          <Alert message={alertMessage} type="info" />
        </div>

        <div></div>
      </div>

      {children}
    </div>
  );
}
