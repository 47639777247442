import React from "react";
import { useNavigate } from "react-router-dom";
import { Result, Button } from "antd";
export default function Confirmation(props) {
  const history = useNavigate();
  return (
    <div>
      {" "}
      <Result
        status="success"
        title={props.confirmationMessage}
        subTitle="An invoice will be sent to your registered email inbox."
        extra={[
          <Button
            key="buy"
            onClick={() => {
              window.location.reload();
            }}
          >
            Buy Again
          </Button>,
        ]}
      />
    </div>
  );
}
