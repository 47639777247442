import React from "react";

const Home = () => {
  return (
    <div>
      <h3>404 PAGE NOT FOUND</h3>
    </div>
  );
};
export default Home;
