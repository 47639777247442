import React, { useState } from "react";
import { Form, Spin, Radio } from "antd";
import PayByCard from "./payByCard";
export default function Payments(props) {
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [loading, setLoading] = useState(false);
  return (
    <div>
      {" "}
      <Spin spinning={loading}>
        <Form.Item
          name={"paymentMethod"}
          label="Pay by check or card?"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => {
              setPaymentMethod(e.target.value);
            }}
          >
            <Radio.Button value="check">Check</Radio.Button>
            <Radio.Button value="card">Card</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {/* {paymentMethod == "card" ? <PayByCard loading={loading} /> : <></>} */}
      </Spin>
    </div>
  );
}
