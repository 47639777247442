import React, { useState } from "react";
import {
  InputNumber,
  Form,
  Typography,
  Checkbox,
  Alert,
  Space,
  Button,
} from "antd";
const { Text, Paragraph, Title } = Typography;

export default function SelectTicketType(props) {
  const isMakingContribution = props.isMakingContribution;
  const showAlert = props.showAlert;

  return (
    <div>
      <Title level={4} strong>
        Golf Tickets/Contributions
      </Title>
      {!isMakingContribution && (
        <>
          <Paragraph>
            Includes Breakfast, Golf, Lunch, and
            <Text strong> Extended Cocktail Reception</Text>
            <br />
            (Inclusive of green fees & carts but excluding caddie gratuities)
          </Paragraph>
          <Form.Item
            name={["sellingItems", "golf"]}
            label={"How many tickets would you like to purchase?"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              onChange={(value) => props.onChange(value, "golf")}
              min={0}
              max={100}
              keyboard={true}
              formatter={(value) => `${value}`.replace(/\./g, "")}
            />
          </Form.Item>
          <Text strong>Extended Cocktail Reception tickets</Text>
          {props.showAlert && (
            <Alert
              message="Cocktail Ticket Already Included"
              description={
                <Paragraph>
                  Just to make it perfectly clear, your cocktail ticket is
                  already included in the golf ticket. Do you still want to
                  purchase additional cocktail tickets?
                  <Space direction="horizontal">
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        props.confirmTicket();
                      }}
                    >
                      Yes, I want additional cocktail tickets.
                    </Button>
                    <Button
                      size="small"
                      danger
                      type="ghost"
                      onClick={() => {
                        props.cancleTicket();
                      }}
                    >
                      No, it was a mistake.
                    </Button>
                  </Space>
                </Paragraph>
              }
              type="warning"
            />
          )}

          <Form.Item
            name={["sellingItems", "cocktail"]}
            label={
              "How many additional Cocktail Reception tickets would you like to purchase?"
            }
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              onChange={(value) => props.onChange(value, "cocktail")}
              min={0}
              max={100}
              keyboard={true}
              formatter={(value) => `${value}`.replace(/\./g, "")}
            />
          </Form.Item>
        </>
      )}

      <Form.Item>
        <Checkbox
          valuePropName="checked"
          checked={isMakingContribution}
          onChange={(e) => {
            props.onChangeType(e.target.checked);
          }}
        >
          I/WE WOULD LIKE TO CONTRIBUTE.
        </Checkbox>
      </Form.Item>
      {isMakingContribution && (
        <Form.Item
          name={["sellingItems", "contribution"]}
          label="How much would you like to contribute?"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            autoFocus={true}
            min={1}
            onChange={(value) => props.onChange(value, "contribution")}
          />
        </Form.Item>
      )}
    </div>
  );
}
