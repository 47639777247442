import React, { useEffect, useState } from "react";
import { Form, Input, Switch, Row, Col, Select } from "antd";
import AddGuests from "./addGuests";
const gutterLayout = { xs: 8, sm: 16, md: 24, lg: 32 };
export default function Billing(props) {
  const { Option } = Select;
  const [ifWithGuests, setIfWithGuests] = useState(false);
  const isMakingContribution = props.isMakingContribution;
  console.log(props.isMakingContribution);
  const changeGuests = (value) => {
    setIfWithGuests(value);
  };
  const stateOptions = () => {
    const data = [
      {
        State: "Alabama",
        Abbrev: "Ala.",
        Code: "AL",
      },
      {
        State: "Alaska",
        Abbrev: "Alaska",
        Code: "AK",
      },
      {
        State: "Arizona",
        Abbrev: "Ariz.",
        Code: "AZ",
      },
      {
        State: "Arkansas",
        Abbrev: "Ark.",
        Code: "AR",
      },
      {
        State: "California",
        Abbrev: "Calif.",
        Code: "CA",
      },
      {
        State: "Colorado",
        Abbrev: "Colo.",
        Code: "CO",
      },
      {
        State: "Connecticut",
        Abbrev: "Conn.",
        Code: "CT",
      },
      {
        State: "Delaware",
        Abbrev: "Del.",
        Code: "DE",
      },
      {
        State: "District of Columbia",
        Abbrev: "D.C.",
        Code: "DC",
      },
      {
        State: "Florida",
        Abbrev: "Fla.",
        Code: "FL",
      },
      {
        State: "Georgia",
        Abbrev: "Ga.",
        Code: "GA",
      },
      {
        State: "Hawaii",
        Abbrev: "Hawaii",
        Code: "HI",
      },
      {
        State: "Idaho",
        Abbrev: "Idaho",
        Code: "ID",
      },
      {
        State: "Illinois",
        Abbrev: "Ill.",
        Code: "IL",
      },
      {
        State: "Indiana",
        Abbrev: "Ind.",
        Code: "IN",
      },
      {
        State: "Iowa",
        Abbrev: "Iowa",
        Code: "IA",
      },
      {
        State: "Kansas",
        Abbrev: "Kans.",
        Code: "KS",
      },
      {
        State: "Kentucky",
        Abbrev: "Ky.",
        Code: "KY",
      },
      {
        State: "Louisiana",
        Abbrev: "La.",
        Code: "LA",
      },
      {
        State: "Maine",
        Abbrev: "Maine",
        Code: "ME",
      },
      {
        State: "Maryland",
        Abbrev: "Md.",
        Code: "MD",
      },
      {
        State: "Massachusetts",
        Abbrev: "Mass.",
        Code: "MA",
      },
      {
        State: "Michigan",
        Abbrev: "Mich.",
        Code: "MI",
      },
      {
        State: "Minnesota",
        Abbrev: "Minn.",
        Code: "MN",
      },
      {
        State: "Mississippi",
        Abbrev: "Miss.",
        Code: "MS",
      },
      {
        State: "Missouri",
        Abbrev: "Mo.",
        Code: "MO",
      },
      {
        State: "Montana",
        Abbrev: "Mont.",
        Code: "MT",
      },
      {
        State: "Nebraska",
        Abbrev: "Nebr.",
        Code: "NE",
      },
      {
        State: "Nevada",
        Abbrev: "Nev.",
        Code: "NV",
      },
      {
        State: "New Hampshire",
        Abbrev: "N.H.",
        Code: "NH",
      },
      {
        State: "New Jersey",
        Abbrev: "N.J.",
        Code: "NJ",
      },
      {
        State: "New Mexico",
        Abbrev: "N.M.",
        Code: "NM",
      },
      {
        State: "New York",
        Abbrev: "N.Y.",
        Code: "NY",
      },
      {
        State: "North Carolina",
        Abbrev: "N.C.",
        Code: "NC",
      },
      {
        State: "North Dakota",
        Abbrev: "N.D.",
        Code: "ND",
      },
      {
        State: "Ohio",
        Abbrev: "Ohio",
        Code: "OH",
      },
      {
        State: "Oklahoma",
        Abbrev: "Okla.",
        Code: "OK",
      },
      {
        State: "Oregon",
        Abbrev: "Ore.",
        Code: "OR",
      },
      {
        State: "Pennsylvania",
        Abbrev: "Pa.",
        Code: "PA",
      },
      {
        State: "Rhode Island",
        Abbrev: "R.I.",
        Code: "RI",
      },
      {
        State: "South Carolina",
        Abbrev: "S.C.",
        Code: "SC",
      },
      {
        State: "South Dakota",
        Abbrev: "S.D.",
        Code: "SD",
      },
      {
        State: "Tennessee",
        Abbrev: "Tenn.",
        Code: "TN",
      },
      {
        State: "Texas",
        Abbrev: "Tex.",
        Code: "TX",
      },
      {
        State: "Utah",
        Abbrev: "Utah",
        Code: "UT",
      },
      {
        State: "Vermont",
        Abbrev: "Vt.",
        Code: "VT",
      },
      {
        State: "Virginia",
        Abbrev: "Va.",
        Code: "VA",
      },
      {
        State: "Washington",
        Abbrev: "Wash.",
        Code: "WA",
      },
      {
        State: "West Virginia",
        Abbrev: "W.Va.",
        Code: "WV",
      },
      {
        State: "Wisconsin",
        Abbrev: "Wis.",
        Code: "WI",
      },
      {
        State: "Wyoming",
        Abbrev: "Wyo.",
        Code: "WY",
      },
    ];
    return data.map((item) => {
      return (
        <Option key={item.Code} value={item.Code}>
          {item.Code}, {item.State}
        </Option>
      );
    });
  };

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    var numberOfStr = cleaned.length;
    if (numberOfStr < 4) {
      var match = cleaned.match(/^\d{3}$/);
      return "(" + match[1] + ") ";
    }
    if ((numberOfStr > 7) & (numberOfStr < 10)) {
      return "(" + match[1] + ") " + match[2] + "-";
    }
    if ((numberOfStr = 10)) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    if (match) {
    }

    return "";
  };
  return (
    <div>
      {" "}
      <Row gutter={gutterLayout}>
        <Col span={12}>
          <Form.Item
            name={["user", "firstName"]}
            label="First Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["user", "lastName"]}
            label="Last Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={gutterLayout}>
        <Col xs={24} md={12}>
          <Form.Item
            name={["user", "email"]}
            label="Email"
            rules={[
              {
                type: "email",
              },
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            name={["user", "phone"]}
            label="Phone"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
            // onChange={(e) => {
            //   var formattedPhoneNumber = formatPhoneNumber(e.target.value);
            //   console.log(formattedPhoneNumber);
            // }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={gutterLayout}>
        <Col span={12}>
          <Form.Item
            name={["user", "company"]}
            label="Company"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["user", "position"]}
            label="Position"
            rules={[
              { max: 15, message: "Position must be less than 15 characters." },
              // {
              //   required: true,
              // },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={["user", "address"]}
        label="Address"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["user", "zip"]}
        label="Zip"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Row gutter={gutterLayout}>
        <Col span={12}>
          <Form.Item
            name={["user", "city"]}
            label="City"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["user", "state"]}
            label="State"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select style={{ width: 180 }} allowClear>
              {stateOptions()}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {!isMakingContribution && (
        <Form.Item
          name={"withGuests"}
          label="Will you bring guests?"
          valuePropName="checked"
        >
          <Switch onChange={changeGuests} />
        </Form.Item>
      )}
      {ifWithGuests ? <AddGuests /> : <></>}
    </div>
  );
}
